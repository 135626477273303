import React, { useEffect, useState, useContext } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import {
  Box,
  Text,
  Image,
  Flex,
  Icon,
  Switch,
  Button,
  Grid,
  Spinner,
  useToast,
  IconButton,
  Divider,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerHeader,
  DrawerBody,
  DrawerCloseButton,
  Input,
  Center,
  InputGroup,
  InputRightElement,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalCloseButton,
  Heading,
} from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import { FaRegEnvelope, FaRegCopy } from 'react-icons/fa6';
import axiosInstance2 from '../../services/UnAuthApi';
import Navbar from '../../components/navbar/navbar';
import { ReactComponent as BackIcon } from '../../assets/previous.svg';
import Footer from '../../components/footer/footer';
import emailIcon from '../../assets/email.svg';
import buyIcon from '../../assets/buy.svg';
import cartIcon from '../../assets/cart.svg';
import { GetUserAccount } from '../../services/accountApi.api';
import CartModal from '../../assets/cartModal.png';
import Success from '../../assets/success.png';
import SkeletonLayout from '../../components/SkeletonLayout';
import Card from '../../assets/payCard.png';
import Wallet from '../../assets/payWallet.png';
import Dropdown from '../../assets/dropDown.png';
import { GetWallet } from '../../services/wallet.api';
import OtpInput from 'react-otp-input';
import PrimaryButton from '../../components/primaryButton/primarybutton';
import EnterPin from '../../assets/enterPin.png';
import PinSuccess from '../../assets/pinSuccess.png';
import DefaultImage from '../../assets/voucher.png';
import { CartContext } from '../myCart/cartContext';
import PrepaidLoginModal from '../../components/prepaidTopUp/prepaidLoginModal';
import MainLayout from '../../components/mainLayout/mainLayout';
import axiosInstance3 from '../../services/api2';

const GameVoucherTopUp = () => {
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [showModal, setShowModal] = useState('');
  const [isPaymentConfirmed, setIsPaymentConfirmed] = useState(false);
  const [otp, setOtp] = useState('');
  const [showDrawer, setShowDrawer] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [gameVouchersTopUp, setGameVouchersTopUp] = useState([]);
  const [wallet, setWallet] = useState(0);
  const [itemName, setItemName] = useState('');
  const [isCartLoading, setIsCartLoading] = useState(false);
  const [selectedVoucher, setSelectedVoucher] = useState(null);
  const [showSidebar, setShowSidebar] = useState(false);
  const [showPaymentDrawer, setShowPaymentDrawer] = useState(false);
  const [showWalletDrawer, setShowWalletDrawer] = useState(false);
  const [quantity, setQuantity] = useState(1);
  const [loadingCart, setLoadingCart] = useState(false);
  const [cartItem, setCartItem] = useState(null);
  const [refetch, setRefetch] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [activeButton, setActiveButton] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isSkeletonLoading, setIsSkeletonLoading] = useState(true);
  const [email, setEmail] = useState('');
  const [validEmail, setValidEmail] = useState(true);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const { id } = useParams();
  const imageUrl = location.state?.imageUrl;
  const gameName = location.state?.name;
  const howToRedeem = location.state?.howToRedeem;
  const toast = useToast();
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(null);
  const [showDeliveryDrawer, setShowDeliveryDrawer] = useState(false);
  const [selectedState, setSelectedState] = useState('');
  const [searchValue, setSearchValue] = useState('');
  const [isStateDropdownOpen, setIsStateDropdownOpen] = useState(false);
  const [address, setAddress] = useState('');
  const [recipientName, setRecipientName] = useState('');
  const [recipientPhoneNumber, setRecipientPhoneNumber] = useState('');
  const [payLoading, setPayLoading] = useState(false);
  const { updateCartItems } = useContext(CartContext);
  const [walletDetails, setWalletDetails] = useState(null);
  const [copied, setCopied] = useState(false);
  const [emailIsLoading, setEmailIsLoading] = useState(true);
  const [triesLeft, setTriesLeft] = useState(5);
  const [isSaveDeliveryDetailsChecked, setIsSaveDeliveryDetailsChecked] =
    useState(false);
  const { cartItems } = useContext(CartContext);
  const currentUser = useSelector(({ userData }) => userData?.currentUser);
  const currentEmail = useSelector(
    ({ userData }) => userData?.userProfile?.userProfile?.email
  );
  const currentRecipientId = useSelector(
    ({ userData }) => userData?.userProfile?.userProfile?.id
  );

  useEffect(() => {}, [cartItems]);

  useEffect(() => {
    const fetchGameVouchers = async () => {
      try {
        const response = await axiosInstance3.get(
          `/game-voucher/available-vouchers/${id}`
        );
        const items = response.data.data.content;
        setGameVouchersTopUp(items);
        // if (items.length > 0) {
        //   setItemName(items[0].name);
        // }
      } catch (error) {
        setIsSkeletonLoading(false);
        handleFetchError(error);
      } finally {
        setIsSkeletonLoading(false);
      }
    };

    fetchGameVouchers();
  }, [id, refetch]);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        if (currentUser) {
          const storedWallet = localStorage.getItem('wallet');
          const storedEmail = localStorage.getItem('email');

          if (storedWallet && storedEmail) {
            setWallet(JSON.parse(storedWallet));
            setEmail(storedEmail);
          } else {
            const balance = await GetWallet();
            const availableBalance = balance?.data?.balance?.availableBalance;

            const emailToSet = currentEmail || storedEmail || '';
            setEmail(emailToSet);

            setWallet(availableBalance);
            setWalletDetails(balance?.data?.details);

            localStorage.setItem('wallet', JSON.stringify(availableBalance));
            localStorage.setItem('email', emailToSet);
          }
        }
      } catch (error) {
        console.error(error);
      } finally {
        setEmailIsLoading(false);
      }
    };

    fetchUserData();
  }, [currentUser, currentEmail]);

  useEffect(() => {
    if (gameVouchersTopUp.length > 0) {
      setIsSkeletonLoading(false);
    }
  }, [gameVouchersTopUp]);

  const isLoggedIn = () => {
    const token = localStorage.getItem('GPV1TK');
    return !!token;
  };

  const handlePayVoucher = async () => {
    setPayLoading(true);

    try {
      await axiosInstance2.put('/cart/clear');

      await handleAddToCart(selectedVoucher, false);

      const data = {
        walletPin: otp,
        checkoutType: 'MANUAL',
      };

      const response = await axiosInstance2.post('/cart/checkout', data);

      if (response.status === 200) {
        setShowSuccessModal(true);
        setShowModal('');
        setShowWalletDrawer(false);
        setShowDeliveryDrawer(false);
        setShowPaymentDrawer(false);
        setShowSidebar(false);
        updateCartItems(cartItems, selectedVoucher);
      } else {
        throw new Error('Payment failed');
      }
    } catch (error) {
      if (error?.response?.status === 500 || error?.response?.status === 501) {
        toast({
          title: '',
          description:
            'An error occurred while processing this request, please contact administrator',
          status: 'error',
          position: 'top-right',
          duration: 5000,
          isClosable: true,
        });
      } else {
        toast({
          title: 'An Error Occurred',
          description: error?.response?.data?.errors[0],
          status: 'error',
          position: 'top-right',
          duration: 5000,
          isClosable: true,
        });
      }
    } finally {
      setPayLoading(false);
      setIsPaymentConfirmed(false);
      setOtp('');
    }
  };

  const handleFetchError = (error, response) => {
    setIsSkeletonLoading(false);

    if (response?.status === 500 || response?.status === 501) {
      toast({
        title: '',
        description:
          'An error occurred while processing this request, please contact administrator',
        status: 'error',
        position: 'top-right',
        duration: 5000,
        isClosable: true,
      });
    } else if (response?.data?.errors[0]?.includes('profile inactive')) {
      // onOpen();
    } else {
      toast({
        title: 'An Error Occurred',
        description: response?.data?.errors[0],
        status: 'error',
        position: 'top-right',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleAddToCart = async (data, showSuccessModal = true) => {
    try {
      setIsLoading(true);
      setIsCartLoading(true);

      if (!data) {
        data = selectedVoucher;
      }

      const { voucherPrice } = data;
      const priceToUse =
        voucherPrice.discountPercent !== 0
          ? voucherPrice.price -
            (voucherPrice.price * voucherPrice.discountPercent) / 100
          : voucherPrice.price;

      const cartItems = [
        {
          ...data,
          price: priceToUse,
          checkoutType: 'MANUAL',
          recipientId: currentRecipientId,
          recipientEmail: email,
          quantity: quantity,
          itemImageUrl: imageUrl,
          itemType: 'VOUCHER',
        },
      ];
      console.log(cartItems);
      const response = await axiosInstance2.post('/cart', {
        cartItems,
      });

      if (response.data.status === 200) {
        setLoadingCart(false);
        setIsConfirmationModalOpen(false);
        if (showSuccessModal) {
          setShowSuccess(true);
        }

        // Update the cart items in the context
        // updateCartItems((prevCartItems) => [...prevCartItems, ...cartItems]);
        updateCartItems((prevCartItems) => [...prevCartItems, ...cartItems]);
      } else {
      }
    } catch (error) {
      handleAddToCartError(error);
    } finally {
      setIsLoading(false);
      setIsCartLoading(false);
      setShowSidebar(false);
    }
  };

  const handleAddToCartError = (error, response) => {
    if (response?.status === 500 || response?.status === 501) {
      toast({
        title: '',
        description:
          'An error occurred while processing this request, please contact administrator',
        status: 'error',
        position: 'top-right',
        duration: 5000,
        isClosable: true,
      });
    } else if (response?.data?.errors[0]?.includes('profile inactive')) {
      // onOpen();
    } else {
      toast({
        title: 'An Error Occurred',
        description: response?.data?.errors[0],
        status: 'error',
        position: 'top-right',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleRemoveVoucherFromCart = async (voucher) => {
    try {
      setIsLoading(true);
      const { itemType, name } = voucher;
      const response = await axiosInstance2.put(`/cart/remove`, {
        itemType,
        name,
      });
      if (response.data.status === 200) {
        // Update the cart items in the context
        updateCartItems((prevCartItems) => {
          const updatedCartItems = prevCartItems.filter(
            (cartItem) => cartItem !== voucher
          );
          return updatedCartItems;
        });
      } else {
      }
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  const handlePaymentConfirmation = () => {
    // Logic to handle payment confirmation
    setIsPaymentConfirmed(true);
    setShowDrawer(true);
    setShowModal('payment');
  };

  const handleChange = (e) => {
    setOtp(e);
  };

  const handleConfirmAddToCart = async () => {
    setIsConfirmationModalOpen(false);
    setLoadingCart(true);
    setEmail(currentEmail);
    const response = await axiosInstance2.post('/cart', cartItem);
    setLoadingCart(false);
  };

  const handleItemClick = (voucher) => {
    setSelectedVoucher(voucher);
    setShowSidebar(true);
  };

  const handleBackClick = () => {
    navigate('/');
  };

  const onClose = () => setShowSidebar(false);
  const onPaymentDrawerClose = () => setShowPaymentDrawer(false);

  const handleQuantityChange = (e) => {
    const newQuantity = Number(e.target.value);

    if (newQuantity > selectedVoucher.count) {
      setQuantity(selectedVoucher.count);
      toast({
        title: '',
        description: `${selectedVoucher.count} ${
          selectedVoucher.count === 1 ? 'unit is' : 'units are'
        } available for ${selectedVoucher.name}.`,
        status: 'error',
        position: 'top-right',
        duration: 5000,
        isClosable: true,
      });
    } else {
      setQuantity(newQuantity);
    }
  };

  const handleDeliveryDetails = (deliveryDetails) => {
    // Implement your logic to handle the delivery details here
  };

  const handleEmailChange = (e) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const isValid = emailRegex.test(e.target.value);
    setEmail(e.target.value);
    setValidEmail(isValid);
  };

  const handleBoxClick = () => {
    setSelectedPaymentMethod('wallet');
    setShowWalletDrawer();
    handleContinue();
  };

  const handleContinue = async () => {
    try {
      const deliveryDetails = {
        state: selectedState,
        address,
        recipientName,
        email,
        recipientPhoneNumber,
        isSaveDeliveryDetailsChecked,
      };

      const priceAfterDiscount =
        selectedVoucher?.voucherPrice?.discountPercent !== 0
          ? selectedVoucher?.voucherPrice?.price -
            (selectedVoucher?.voucherPrice?.price *
              selectedVoucher?.voucherPrice?.discountPercent) /
              100
          : selectedVoucher?.voucherPrice?.price;

      const totalCost = priceAfterDiscount * quantity;

      const canPay = wallet >= totalPrice;
      handleDeliveryDetails(deliveryDetails);
      setActiveButton(canPay);
      setShowDeliveryDrawer(false);
      setShowWalletDrawer(true);
    } catch (error) {
      toast({
        title: 'Error',
        description: error.message,
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const totalPrice = selectedVoucher
    ? Math.round(
        (selectedVoucher?.voucherPrice?.price -
          (selectedVoucher?.voucherPrice?.price *
            selectedVoucher?.voucherPrice?.discountPercent) /
            100) *
          quantity
      )
    : 0;

  const priceAfterDiscount =
    selectedVoucher?.voucherPrice?.discountPercent !== 0
      ? Math.round(
          selectedVoucher?.voucherPrice?.price -
            (selectedVoucher?.voucherPrice?.price *
              selectedVoucher?.voucherPrice?.discountPercent) /
              100
        )
      : selectedVoucher?.voucherPrice?.price;

  const totalCost = priceAfterDiscount * quantity;

  const extractFromFirstNumber = (input) => {
    const match = input.match(/\d.*$/);
    if (match) {
      return match[0].replace(/[()]/g, '');
    }
    return '';
  };
  return (
    <>
      <MainLayout searchValue={searchValue} setSearchValue={setSearchValue}>
        {isSkeletonLoading ? (
          <Box mt="120px">
            <SkeletonLayout />
          </Box>
        ) : (
          <>
            {' '}
            {gameVouchersTopUp.length === 0 ? (
              <Box m={{ base: '10px', md: '30px' }}>
                <Flex align="center" mt="120px" zIndex="1000">
                  <IconButton
                    icon={<BackIcon />}
                    mr="20px"
                    ml="10px"
                    cursor="pointer"
                    onClick={handleBackClick}
                  />
                  <Text fontSize="24px" fontWeight="700">
                    {gameName}
                  </Text>
                </Flex>
                <Flex
                  direction="column"
                  alignItems="center"
                  justifyContent="center"
                  textAlign="center"
                  my="40px"
                >
                  <Image
                    src={DefaultImage}
                    height="222px"
                    width="222px"
                    alt="Not available"
                    mb="1rem"
                    mt="2rem"
                  />

                  <Text
                    fontWeight={'400'}
                    fontSize={'16px'}
                    color="#999999"
                    ml="40px"
                    mb="40px"
                  >
                    There are no vouchers available for this game.
                  </Text>
                </Flex>
              </Box>
            ) : (
              <Box m={{ base: '10px', md: '30px' }}>
                {/* <Box mb="20px">
                <Navbar />
              </Box> */}
                <Flex align="center" mt="120px" zIndex="1000">
                  <IconButton
                    icon={<BackIcon />}
                    mr="20px"
                    ml="10px"
                    cursor="pointer"
                    onClick={handleBackClick}
                  />
                  <Text fontSize="24px" fontWeight="700">
                    {gameName}
                  </Text>
                </Flex>
                <Grid
                  templateColumns={{
                    base: 'repeat(2, 1fr)',
                    md: 'repeat(4, 1fr)',
                    lg: 'repeat(6, 1fr)',
                  }}
                  // templateColumns="repeat(auto-fit, minmax(150px, 1fr))"
                  spacing={5}
                  // gap={7}
                  mt="30px"
                >
                  {gameVouchersTopUp
                    .sort((a, b) => {
                      const priceA =
                        a.voucherPrice.price -
                        (a.voucherPrice.price *
                          a.voucherPrice.discountPercent) /
                          100;
                      const priceB =
                        b.voucherPrice.price -
                        (b.voucherPrice.price *
                          b.voucherPrice.discountPercent) /
                          100;
                      return priceA - priceB;
                    })
                    .map((game) => (
                      <Flex
                        key={game.id}
                        direction="column"
                        align="center"
                        mb="30px"
                        mx={['auto', '10px']}
                        width={{ base: '160px', md: '180px' }}
                        border="1px solid none"
                        borderRadius="10px"
                        paddingX="2px"
                        paddingY="5px"
                        boxShadow="md"
                        height={'350px'}
                        position={'relative'}
                        onClick={() => handleItemClick(game)}
                      >
                        <Box
                          height={'200px'}
                          width="100%"
                          maxWidth="400px"
                          position={'relative'}
                          overflow="hidden"
                          backgroundImage={`url(${imageUrl || game.image})`}
                          backgroundSize="cover"
                          backgroundPosition="center"
                          borderRadius="10px"
                          mb="20px"
                          cursor="pointer"
                        />
                        <Text
                          fontSize="0.85rem"
                          fontWeight="700"
                          mb={{ base: '5px', md: '5px' }}
                          w="100%"
                          textAlign={'left'}
                        >
                          {game.name}
                        </Text>
                        <Flex
                          fontSize="16px"
                          fontWeight="400"
                          color="gray.500"
                          mb="10px"
                          width="100%"
                          alignItems="center"
                          // position={'absolute'}
                          // bottom={'0'}
                          paddingX="5px"
                        >
                          <Text
                            color="black"
                            fontWeight="700"
                            fontSize="0.9rem"
                          >
                            ₦
                            {Math.round(
                              game.voucherPrice.price -
                                (game.voucherPrice.price *
                                  game.voucherPrice.discountPercent) /
                                  100
                            ).toLocaleString()}
                          </Text>
                          {game.voucherPrice.discountPercent > 0 && (
                            <Text ml="auto" textDecoration="line-through">
                              ₦{game.voucherPrice.price.toLocaleString()}
                            </Text>
                          )}
                        </Flex>
                        <Button
                          width={'100%'}
                          mt={2}
                          bg={'#002E99'}
                          textColor={'#FFF'}
                          borderRadius={'20px'}
                          py={1}
                          fontWeight={400}
                        >
                          Buy now
                        </Button>
                      </Flex>
                    ))}
                </Grid>
                {/* How to Redeem */}
                <Box
                  p="1rem"
                  dangerouslySetInnerHTML={{ __html: howToRedeem }}
                />
              </Box>
            )}
          </>
        )}
        <Drawer isOpen={showSidebar} placement="right" onClose={onClose}>
          <DrawerOverlay />
          <DrawerContent maxWidth="500px">
            <DrawerCloseButton border={'1px solid gray'} borderRadius="50%" />
            {/* <DrawerHeader>Voucher Details</DrawerHeader> */}
            <DrawerBody marginTop={'2.5rem'}>
              {selectedVoucher && (
                <>
                  <Flex align="center" justify="center">
                    <Box height="240px" width="420px">
                      <Image
                        src={imageUrl || selectedVoucher.image}
                        alt="Voucher Image"
                        objectFit="cover"
                        width="100%"
                        height="100%"
                        borderRadius="10px"
                      />
                    </Box>
                  </Flex>
                  <Text my="24px" fontSize="24px" fontWeight="700">
                    {selectedVoucher.name}
                  </Text>
                  <Text fontSize="16px" fontWeight="700">
                    Purchase details
                  </Text>
                  <Box
                    bg="#F5F8FF"
                    p="10px"
                    borderRadius="8px"
                    mb="10px"
                    textColor="#999999"
                    fontSize="14px"
                    fontWeight="400"
                  >
                    <Flex justifyContent="space-between" mb="16px">
                      <Text>Game points</Text>
                      <Text>
                        {extractFromFirstNumber(selectedVoucher.name)}
                      </Text>
                    </Flex>
                    <Flex justifyContent="space-between">
                      <Text>Price</Text>
                      <Text>₦{totalCost?.toLocaleString()}</Text>
                    </Flex>
                  </Box>
                  <Flex direction="column" gap="10px">
                    <Box>
                      <Text
                        mb="5px"
                        textColor="#999999"
                        fontWeight="400px"
                        fontSize="12px"
                      >
                        Quantity
                      </Text>
                      <Flex
                        alignItems="center"
                        bg="#F5F8FF"
                        p="10px"
                        borderRadius="8px"
                      >
                        <Input
                          flex="1"
                          height="30px"
                          value={quantity || ''}
                          onChange={handleQuantityChange}
                          type="int"
                          min="1"
                          border="none"
                          placeholder="Enter quantity here"
                          _placeholder={{
                            color: '#999999',
                            fontWeight: '400',
                            fontSize: '14px',
                          }}
                          sx={{
                            '&:focus': {
                              outline: 'none',
                              boxShadow: 'none',
                            },
                          }}
                        />
                      </Flex>
                    </Box>
                    <Box>
                      <Text
                        mb="5px"
                        textColor="#999999"
                        fontWeight="400px"
                        fontSize="12px"
                      >
                        Recipient mail
                      </Text>
                      <Flex
                        alignItems="center"
                        bg="#F5F8FF"
                        p="10px"
                        borderRadius="8px"
                      >
                        <InputGroup flex="1">
                          <Input
                            height="30px"
                            value={email || ''}
                            onChange={handleEmailChange}
                            type="email"
                            border={validEmail ? 'none' : '1px solid red'}
                            placeholder={email ? email : 'user@example.com'}
                            _placeholder={{
                              color: '#999999',
                              fontWeight: '400',
                              fontSize: '14px',
                            }}
                            sx={{
                              '&:focus': {
                                outline: 'none',
                                boxShadow: 'none',
                              },
                            }}
                          />
                          <InputRightElement>
                            <FaRegEnvelope />
                          </InputRightElement>
                        </InputGroup>
                      </Flex>
                      {!validEmail && (
                        <Text color="red" fontSize="12px" mt="2px">
                          A valid email address is required
                        </Text>
                      )}
                    </Box>
                    <Box>
                      <Text
                        mb="5px"
                        textColor="#999999"
                        fontWeight="400px"
                        fontSize="12px"
                      >
                        Total price
                      </Text>
                      <Flex
                        alignItems="center"
                        bg="#F5F8FF"
                        p="10px"
                        borderRadius="8px"
                      >
                        <Box
                          flex="1"
                          textAlign="left"
                          fontWeight="400"
                          color="#999999"
                          fontSize="14px"
                        >
                          ₦{totalPrice?.toLocaleString()}
                        </Box>
                      </Flex>
                    </Box>
                    <Flex>
                      <Flex
                        mt="1rem"
                        mb="30px"
                        width="100%"
                        flexDirection={'column'}
                      >
                        <Button
                          backgroundColor="#002E99"
                          textColor="white"
                          borderRadius="10px"
                          px="45px"
                          fontSize="16px"
                          fontWeight="700"
                          mr={{ base: '10px', md: '20px' }}
                          width="100%"
                          onClick={() => {
                            if (!isLoggedIn()) {
                              setShowLoginModal(true);
                            } else {
                              setShowPaymentDrawer(true);
                            }
                          }}
                          isDisabled={
                            !validEmail ||
                            email === '' ||
                            !quantity ||
                            quantity < 1
                          }
                        >
                          Buy Now
                          <Image src={buyIcon} mr="5px" alt="Buy Icon" />
                        </Button>

                        <Box
                          display="flex"
                          alignItems="center"
                          justifyContent="center"
                          mt="1rem"
                        >
                          <Box
                            height="1px"
                            width="49%"
                            backgroundColor="#999"
                          />
                          <Text mx="10px">OR</Text>
                          <Box
                            height="1px"
                            width="49%"
                            backgroundColor="#999"
                          />
                        </Box>

                        <Button
                          border="1px solid #999999"
                          bg="white !important"
                          color="#000000"
                          borderRadius="10px"
                          px="35px"
                          fontSize="16px"
                          fontWeight="700"
                          mr={{ base: '10px', md: '20px' }}
                          mt="1rem"
                          width="100%"
                          onClick={() => {
                            if (!isLoggedIn()) {
                              setShowLoginModal(true);
                            } else {
                              handleAddToCart(selectedVoucher);
                            }
                          }}
                          isDisabled={
                            isCartLoading ||
                            !validEmail ||
                            email === '' ||
                            !quantity ||
                            quantity < 1
                          }
                        >
                          Add to cart
                          <Image src={cartIcon} mr="5px" alt="Buy Icon" />
                        </Button>
                      </Flex>
                    </Flex>
                  </Flex>
                </>
              )}
            </DrawerBody>
          </DrawerContent>
        </Drawer>
        <Box bottom="0">
          <Footer />
        </Box>

        {/* Confirmation Modal */}
        {/* <Modal
        isOpen={isConfirmationModalOpen}
        onClose={() => setIsConfirmationModalOpen(false)}
      >
        <ModalOverlay />
        <ModalContent borderRadius="md" maxWidth="500px" width="500px">
          <ModalHeader fontWeight="700" fontSize="24px" textAlign="center">
            <Box display="flex" justifyContent="center" alignItems="center">
              <Image src={CartModal} boxSize="150px" alt="Icon" />
            </Box>
            Are you sure you want to take action?
          </ModalHeader>
          <ModalBody>
            <Text fontWeight="400" fontSize="14px" textAlign="center">
              Item will be added to cart and can be removed or checked out from
              the cart page.
            </Text>
          </ModalBody>
          <ModalFooter justifyContent="center" pb={10} px={6}>
            <Flex direction="column" alignItems="center">
              <Button
                onClick={() => {
                  handleAddToCart(selectedVoucher);
                }}
                backgroundColor="#002E99"
                textColor="white"
                width="420px"
                mb={2}
                maxWidth="420px"
                disabled={isLoading}
              >
                {isLoading ? (
                  <Spinner size="sm" color="white" />
                ) : (
                  "Add to cart"
                )}
              </Button>
              <Button
                onClick={() => setIsConfirmationModalOpen(false)}
                variant="outline"
                width="420px"
                maxWidth="420px"
              >
                Cancel
              </Button>
            </Flex>
          </ModalFooter>
        </ModalContent>
      </Modal> */}

        <Drawer
          isOpen={showPaymentDrawer}
          placement="right"
          onClose={onPaymentDrawerClose}
          size="md"
        >
          <DrawerOverlay />
          <DrawerContent p="10px" borderRadius="10px">
            <DrawerCloseButton />
            <Text mt="10px" ml="25px" fontWeight="700" fontSize="28px" mb="0">
              Payment Method
            </Text>
            <Text
              ml="25px"
              fontWeight="400"
              fontSize="14px"
              mt="0"
              mb="4"
              textColor="#999999"
            >
              Choose payment mode below to proceed.
            </Text>
            <DrawerBody>
              <Flex direction="column" mb="80px">
                <Box
                  cursor={
                    selectedPaymentMethod === 'wallet'
                      ? 'pointer'
                      : 'not-allowed'
                  }
                  border="1px solid white"
                  borderRadius="8px"
                  p="10px"
                  m="5px"
                  mb="35px"
                  flex="1"
                  boxShadow="md"
                  onClick={
                    selectedPaymentMethod === 'wallet'
                      ? () => setSelectedPaymentMethod('card')
                      : undefined
                  }
                  opacity="0.5"
                  filter="grayscale(20%)"
                >
                  <Flex alignItems="center">
                    <Image src={Card} w="50px" h="50px" mr="10px" />
                    <Box>
                      <Text fontSize="16px" fontWeight="700">
                        Pay with Card
                      </Text>
                      <Text fontSize="14px" fontWeight="400" color="#999999">
                        Pay with your debit or credit card for fast
                      </Text>
                      <Text fontSize="14px" fontWeight="400" color="#999999">
                        and secure payment.
                      </Text>
                    </Box>
                  </Flex>
                </Box>

                <Box
                  cursor="pointer"
                  border={
                    selectedPaymentMethod === 'wallet'
                      ? '1px solid #002E99'
                      : '1px solid white'
                  }
                  borderRadius="8px"
                  p="10px"
                  m="5px"
                  flex="1"
                  boxShadow="md"
                  onClick={handleBoxClick}
                >
                  <Flex alignItems="center">
                    <Image src={Wallet} w="50px" h="50px" mr="10px" />
                    <Box>
                      <Text fontSize="16px" fontWeight="700">
                        Pay with Wallet
                      </Text>
                      <Text fontSize="14px" fontWeight="400" color="#999999">
                        Pay with your porteshop in-app wallet for
                      </Text>
                      <Text fontSize="14px" fontWeight="400" color="#999999">
                        seamless checkout.
                      </Text>
                    </Box>
                  </Flex>
                </Box>
              </Flex>

              <Flex direction="column">
                {/* <Button
                backgroundColor="#002E99"
                mt="20px"
                onClick={handleProceedToPay}
                isLoading={isLoading}
                textColor="white"
                borderRadius="10px"
                disabled={!selectedPaymentMethod}
              >
                Proceed to Pay
              </Button> */}
                <Button
                  border="1px solid #999999"
                  backgroundColor="white"
                  textColor="#999999"
                  mt="20px"
                  borderRadius="10px"
                  onClick={onPaymentDrawerClose}
                >
                  Go Back
                </Button>
              </Flex>
            </DrawerBody>
          </DrawerContent>
        </Drawer>

        <Drawer
          isOpen={showDeliveryDrawer}
          placement="right"
          onClose={() => setShowDeliveryDrawer(false)}
          size="md"
        >
          <DrawerOverlay />
          <DrawerContent p="10px" borderRadius="10px">
            <DrawerCloseButton />
            <Text mt="10px" ml="25px" fontWeight="700" fontSize="28px" mb="0">
              Delivery Details
            </Text>
            <Text
              ml="25px"
              fontWeight="400"
              fontSize="14px"
              mt="0"
              mb="4"
              textColor="#999999"
            >
              Enter delivery details below to proceed.
            </Text>
            <DrawerBody>
              <Flex direction="column" gap="4">
                {/* State */}
                <Box position="relative">
                  <Text mb="2" fontWeight="400" fontSize="12px" color="#999999">
                    State
                  </Text>
                  <InputGroup>
                    <Input
                      placeholder="Select state"
                      value={selectedState}
                      onChange={(e) => setSelectedState(e.target.value)}
                      pr="40px"
                      backgroundColor="#F5F8FF"
                      border="none"
                      _placeholder={{
                        color: '#999999',
                        fontWeight: '400',
                        fontSize: '14px',
                      }}
                    />
                    <InputRightElement
                      width="40px"
                      onClick={() =>
                        setIsStateDropdownOpen(!isStateDropdownOpen)
                      }
                      cursor="pointer"
                    >
                      <Image
                        aria-label="Select state"
                        src={Dropdown}
                        variant="ghost"
                        width="24px"
                        height="24px"
                      />
                    </InputRightElement>
                  </InputGroup>
                  {isStateDropdownOpen && (
                    <Box
                      position="absolute"
                      zIndex="modal"
                      bg="white"
                      boxShadow="md"
                      maxHeight="200px"
                      overflowY="auto"
                    >
                      {/* {states.map((state) => (
                      <Text
                        key={state}
                        px="4"
                        py="2"
                        cursor="pointer"
                        onClick={() => {
                          setSelectedState(state);
                          setIsStateDropdownOpen(false);
                        }}
                      >
                        {state}
                      </Text>
                    ))} */}
                    </Box>
                  )}
                </Box>

                {/* Address */}
                <Box>
                  <Text mb="2" fontWeight="400" fontSize="12px" color="#999999">
                    Address
                  </Text>
                  <Input
                    placeholder="Enter address"
                    value={address}
                    onChange={(e) => setAddress(e.target.value)}
                    backgroundColor="#F5F8FF"
                    border="none"
                    _placeholder={{
                      color: '#999999',
                      fontWeight: '400',
                      fontSize: '14px',
                    }}
                  />
                </Box>

                {/* Recipient Name */}
                <Box>
                  <Text mb="2" fontWeight="400" fontSize="12px" color="#999999">
                    Recipient Name
                  </Text>
                  <Input
                    placeholder="Enter recipient name"
                    value={recipientName}
                    onChange={(e) => setRecipientName(e.target.value)}
                    backgroundColor="#F5F8FF"
                    border="none"
                    _placeholder={{
                      color: '#999999',
                      fontWeight: '400',
                      fontSize: '14px',
                    }}
                  />
                </Box>

                {/* Recipient Phone Number */}
                <Box>
                  <Text mb="2" fontWeight="400" fontSize="12px" color="#999999">
                    Recipient Phone Number
                  </Text>
                  <Input
                    placeholder="Enter recipient phone number"
                    value={recipientPhoneNumber}
                    onChange={(e) => setRecipientPhoneNumber(e.target.value)}
                    backgroundColor="#F5F8FF"
                    border="none"
                    _placeholder={{
                      color: '#999999',
                      fontWeight: '400',
                      fontSize: '14px',
                      paddingTop: '17px',
                      paddingBottom: '17px',
                    }}
                  />
                </Box>

                {/* Toggle */}
                <Flex alignItems="center">
                  <Switch
                    isChecked={isSaveDeliveryDetailsChecked}
                    onChange={() =>
                      setIsSaveDeliveryDetailsChecked(
                        !isSaveDeliveryDetailsChecked
                      )
                    }
                  />
                  <Text ml="2" fontWeight="400" fontSize="14px" color="#999999">
                    Save delivery details
                  </Text>
                </Flex>
              </Flex>
              <Flex direction="column" mt="56px">
                <Button
                  backgroundColor="#002E99"
                  textColor="white"
                  mb="24px"
                  onClick={handleContinue}
                >
                  Continue
                </Button>
                <Button
                  backgroundColor="white"
                  border="1px solid #999999"
                  textColor="#999999"
                  onClick={() => setShowDeliveryDrawer(false)}
                >
                  Go Back
                </Button>
              </Flex>
            </DrawerBody>
          </DrawerContent>
        </Drawer>

        {/* Wallet Checkout Drawer */}
        <Drawer
          isOpen={showWalletDrawer}
          placement="right"
          onClose={() => setShowWalletDrawer(false)}
          size="md"
        >
          <DrawerOverlay />
          <DrawerContent p="10px" borderRadius="10px">
            <DrawerCloseButton />
            <Text mt="10px" ml="25px" fontWeight="700" fontSize="28px" mb="0">
              Wallet Checkout
            </Text>
            <Text fontWeight="400" fontSize="14px" ml="25px">
              Confirm purchase details below before you proceed.
            </Text>
            <DrawerBody>
              <Flex direction="column" gap="4">
                {/* Top Part: Wallet Balance */}
                <Box boxShadow="md" p="4" borderRadius="md">
                  <Flex alignItems="center" justifyContent="space-between">
                    <Flex alignItems="center">
                      <Image
                        src={Wallet}
                        alt="Wallet Icon"
                        boxSize="50px"
                        mr="2"
                      />
                      <Box>
                        <Text fontWeight="400" fontSize="12px" color="#999999">
                          Wallet Balance
                        </Text>
                        <Text fontWeight="500" fontSize="16px" color="#333333">
                          ₦{wallet?.toLocaleString()}
                        </Text>
                      </Box>
                    </Flex>
                    <Text
                      size="sm"
                      backgroundColor="#002E99"
                      textColor="white"
                      fontSize="12px"
                      fontWeight="700"
                      borderRadius="20px"
                      padding="6px 8px"
                      display="inline-block"
                      onClick={() => setShowModal('topup')}
                      cursor={'pointer'}
                    >
                      Top Up Wallet
                    </Text>
                  </Flex>
                </Box>

                {/* Second Part: Item Details */}
                <Box bg="#F5F8FF" p="4" borderRadius="md">
                  <Grid templateColumns="1fr 1fr" gap="3">
                    <Text fontWeight="400" fontSize="12px" color="#999999">
                      Voucher Name
                    </Text>
                    <Text
                      fontWeight="400"
                      fontSize="12px"
                      color="#333333"
                      textAlign="right"
                    >
                      {selectedVoucher?.name}
                    </Text>

                    <Text fontWeight="400" fontSize="12px" color="#999999">
                      Total Cost
                    </Text>
                    <Text
                      fontWeight="400"
                      fontSize="0.9rem"
                      color="#333333"
                      textAlign="right"
                    >
                      ₦{totalCost?.toLocaleString()}
                    </Text>
                  </Grid>

                  <Divider borderColor="#E0E0E0" my="2" />

                  <Grid templateColumns="1fr 1fr" gap="3">
                    <Text fontWeight="400" fontSize="12px" color="#999999">
                      Wallet Balance After Payment
                    </Text>
                    <Text
                      fontWeight={wallet - totalPrice < 0 ? '600' : '700'}
                      fontSize={wallet - totalPrice < 0 ? '12px' : '0.9rem'}
                      color={wallet - totalPrice < 0 ? 'crimson' : '#333333'}
                      textAlign="right"
                    >
                      {wallet - totalPrice < 0
                        ? 'Insufficient Balance'
                        : `₦${(wallet - totalPrice)?.toLocaleString()}`}
                    </Text>
                  </Grid>
                </Box>

                {/* Delivery Details */}
                <Text fontSize="14px" fontWeight="400">
                  Delivery Details
                </Text>
                <Box bg="#F5F8FF" p="4" borderRadius="md" boxShadow="md">
                  <Grid templateColumns="1fr 1fr" gap="3">
                    <Text fontWeight="400" fontSize="12px" color="#999999">
                      Email Address
                    </Text>
                    <Text
                      fontWeight="400"
                      fontSize="14px"
                      color="#333333"
                      textAlign="right"
                    >
                      {email}
                    </Text>
                  </Grid>
                </Box>
              </Flex>

              {/* Bottom Part: Buttons */}
              <Flex direction="column" mt="56px">
                <Button
                  backgroundColor={activeButton ? '#002E99' : '#CCCCCC'}
                  textColor={activeButton ? 'white' : '#666666'}
                  mb="24px"
                  isDisabled={!activeButton}
                  onClick={handlePaymentConfirmation}
                >
                  Pay ₦{totalCost?.toLocaleString()} now
                </Button>
                <Button
                  backgroundColor="white"
                  border="1px solid #999999"
                  textColor="#999999"
                  onClick={() => setShowWalletDrawer(false)}
                >
                  Go Back
                </Button>
              </Flex>
            </DrawerBody>
          </DrawerContent>
        </Drawer>

        {showModal === 'topup' && (
          <Modal isOpen={showModal} onClose={() => setShowModal('')}>
            <ModalOverlay />
            <ModalContent
              width="500px"
              height="auto"
              pt={'3rem'}
              mx={['1rem', 0]}
            >
              <Text textAlign="center" fontWeight="700" fontSize="24px">
                Top Up
              </Text>
              <Text
                textAlign="center"
                fontWeight="400"
                fontSize="16px"
                px={'2rem'}
              >
                Proceed to your Bank app to top-up your Gameporte Wallet
              </Text>

              <ModalCloseButton />
              <ModalBody>
                <Box p="1">
                  <Box bg="#F5F8FF" p="2" m="1" borderRadius="md">
                    <Flex
                      justifyContent={'space-between'}
                      alignItems={'center'}
                    >
                      <Text fontWeight="400" fontSize="12px" color="#999999">
                        Account Number
                        <Text fontWeight="400" fontSize="12px" color="#333333">
                          {walletDetails?.virtualBankAccountNumber}
                        </Text>
                      </Text>

                      <Text
                        size="sm"
                        backgroundColor="#002E99"
                        textColor="white"
                        fontSize="12px"
                        fontWeight="700"
                        borderRadius="20px"
                        padding="6px 8px"
                        w={'fit-content'}
                        onClick={() => setShowModal('topup')}
                        cursor={'pointer'}
                        textAlign={'right'}
                      >
                        <Flex
                          justifyContent={'space-between'}
                          alignItems={'center'}
                          onClick={() => {
                            if (
                              navigator?.clipboard &&
                              walletDetails?.virtualBankAccountNumber
                            ) {
                              navigator.clipboard
                                .writeText(
                                  walletDetails.virtualBankAccountNumber
                                )
                                .then(() => {
                                  setCopied(true);
                                  setTimeout(() => {
                                    setCopied(false);
                                  }, 2000);
                                });
                            }
                          }}
                        >
                          {!copied ? (
                            <>
                              {' '}
                              <FaRegCopy
                                style={{ marginRight: '.3rem' }}
                              />{' '}
                              Copy
                            </>
                          ) : (
                            'Copied!'
                          )}
                        </Flex>
                      </Text>
                    </Flex>
                  </Box>
                  <Box bg="#F5F8FF" p="2" m="1" borderRadius="md">
                    <Text fontWeight="400" fontSize="12px" color="#999999">
                      Bank Name
                    </Text>
                    <Text fontWeight="400" fontSize="0.9rem" color="#333333">
                      {walletDetails?.virtualBankName}
                    </Text>
                  </Box>
                  <Box bg="#F5F8FF" p="2" m="1" borderRadius="md">
                    <Text fontWeight="400" fontSize="12px" color="#999999">
                      Account Name
                    </Text>
                    <Text fontWeight="400" fontSize="0.9rem" color="#333333">
                      {walletDetails?.virtualBankAccountName}
                    </Text>
                  </Box>
                </Box>

                <PrimaryButton
                  bg="#002E99 !important"
                  color="#FFF"
                  text="Close"
                  width="100%"
                  padding="1rem !important"
                  marginBottom="30px"
                  fontWeight="700"
                  fontSize="14px"
                  mt=".5rem"
                  onClick={() => setShowModal('')}
                />
              </ModalBody>
            </ModalContent>
          </Modal>
        )}

        {showModal === 'payment' && (
          <Modal isOpen={showModal} onClose={() => setShowModal('')}>
            <ModalOverlay />
            <ModalContent width="500px" height="516px">
              <Flex align="center">
                <Image
                  textAlign="center"
                  justifyContent="center"
                  src={EnterPin}
                  height="80px"
                  width="80px"
                  mx="auto"
                  my="2rem"
                  objectFit="cover"
                />
              </Flex>
              <Text textAlign="center" fontWeight="700" fontSize="24px">
                Enter Pin
              </Text>
              <Text textAlign="center" fontWeight="400" fontSize="16px">
                Enter your PIN to proceed and
              </Text>
              <Text
                textAlign="center"
                fontWeight="400"
                fontSize="16px"
                mb="24px"
              >
                complete your payment.{' '}
              </Text>
              <ModalCloseButton />
              <ModalBody>
                <Box margin="auto" width={['85%', '70%']}>
                  <Flex direction="row" justifyContent="center" gap="1rem">
                    <OtpInput
                      containerStyle={{
                        display: 'flex',
                        margin: '0 auto',
                        justifyContent: 'space-between',
                        gap: '1rem',
                        marginBottom: '30px',
                      }}
                      inputStyle={{
                        type: 'password',
                        height: '58px',
                        width: '58px',
                        background: '#C2D4FF',
                        color: '#000',
                        fontSize: '1.1rem',
                        fontWeight: '600',
                        borderRadius: '12px',
                        border: 'none',
                      }}
                      focusStyle={{
                        boxShadow: 'none',
                        outline: 'none',
                      }}
                      value={otp}
                      onChange={handleChange}
                      numInputs={4}
                      isInputNum={true}
                      shouldAutoFocus={true}
                      isInputSecure={true}
                    />
                  </Flex>
                </Box>
                <Flex justifyContent={'center'} my="1.3rem" direction="column">
                  <PrimaryButton
                    bg="#002E99 !important"
                    color="#FFF"
                    text="Pay now"
                    width="100%"
                    padding="1rem !important"
                    marginBottom="30px"
                    fontWeight="700"
                    fontSize="14px"
                    onClick={() => handlePayVoucher()}
                    isLoading={payLoading}
                    spinner={<Spinner size="sm" color="white" />}
                  >
                    {' '}
                    {payLoading ? (
                      <Spinner size="sm" color="white" />
                    ) : (
                      'Pay now'
                    )}
                  </PrimaryButton>
                  <PrimaryButton
                    border="1px solid #999999"
                    bg="white !important"
                    color="#999999"
                    text="Cancel"
                    width="100%"
                    padding="1rem !important"
                    fontWeight="700"
                    fontSize="14px"
                    onClick={() => setShowModal('')}
                  />
                </Flex>
              </ModalBody>
            </ModalContent>
          </Modal>
        )}

        <Modal isOpen={showSuccess} onClose={() => setShowSuccess(false)}>
          <ModalOverlay />
          <ModalContent borderRadius="md" maxWidth="450px" width="450px">
            <ModalHeader fontWeight="700" fontSize="24px" textAlign="center">
              <Box display="flex" justifyContent="center" alignItems="center">
                <Image src={Success} boxSize="150px" alt="Icon" />
              </Box>
              Item added to cart!
            </ModalHeader>
            <ModalBody>
              <Text fontWeight="400" fontSize="14px" textAlign="center">
                Item has been added to cart successfully.
              </Text>
            </ModalBody>
            <ModalFooter justifyContent="center" pb={10} px={6}>
              <Flex direction="column" alignItems="center">
                <Button
                  onClick={() => {
                    setShowSuccess(false);
                    setRefetch((prev) => !prev);
                  }}
                  backgroundColor="#002E99"
                  textColor="white"
                  width="420px"
                  mb={2}
                  maxWidth="420px"
                >
                  Continue Shopping
                </Button>
              </Flex>
            </ModalFooter>
          </ModalContent>
        </Modal>

        <Modal
          isOpen={showSuccessModal}
          onClose={() => setShowSuccessModal(false)}
        >
          <ModalOverlay />
          <ModalContent width="500px" height="416px">
            <Flex align="center">
              <Image
                textAlign="center"
                justifyContent="center"
                src={PinSuccess}
                height="150px"
                width="150px"
                mx="auto"
                my="2rem"
                objectFit="cover"
              />
            </Flex>
            <ModalBody>
              <Box flexDirection="column" textAlign="center">
                <Text fontWeight="700" fontSize="24px">
                  Payment Successful
                </Text>
                <Text fontWeight="400" fontSize="14px">
                  Payment made successfully and game voucher
                </Text>
                <Text fontWeight="400" fontSize="14px">
                  has been sent to your email address.
                </Text>
                <Button
                  mt="56px"
                  backgroundColor="#002E99"
                  textColor="white"
                  width="100%"
                  onClick={() => {
                    setShowSuccessModal(false);
                    setRefetch((prev) => !prev);
                  }}
                >
                  Close
                </Button>
              </Box>
            </ModalBody>
          </ModalContent>
        </Modal>
        <PrepaidLoginModal
          isOpen={showLoginModal}
          onClose={() => setShowLoginModal(false)}
        />
      </MainLayout>
    </>
  );
};

export default GameVoucherTopUp;
